import React, { useContext } from "react"
import Template from "../components/template"
import "../styles/blog.css"
import { ImpressumContent } from "../content/impressum"
import { SettingsContext } from "../contexts/settings"

const ImpressumPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = ImpressumContent[settings.language]
    return (
        <Template>
            <section>
                <div>
                    <h1>{content.headline}</h1>
                    <h3>{content.secondHeadlineFirst}</h3>
                    <h3>{content.secondHeadlineSecond}</h3>
                    <p>
                        {content.firstFirst} <br/> 
                        {content.firstSecond}<br/>
                        {content.firstThird}<br/>
                        {content.firstFourth}<br/>
                        {content.firstFifth}
                    </p>
                    <h3>{content.secondHeadlineThird}</h3>
                    <p>
                        {content.contact}
                    </p>
                    <h3>{content.secondHeadlineSeventh}</h3>
                    <h3>{content.secondHeadlineFourth}</h3>
                    <p>
                        {content.second}             
                    </p>
                    <h3>{content.secondHeadlineFifth}</h3>
                    <p>
                        {content.thirdFirst}<br/>
                        {content.thirdSecond}
                    </p>
                    <h3>{content.secondHeadlineSixth}</h3>
                    <p className="end-of-site">
                        {content.fourth}
                    </p>
                </div>    
            </section>
        </Template >
    )
}

export default ImpressumPage