const ImpressumContent = {
    german: {
        headline: "IMPRESSUM",
        secondHeadlineFirst: "Angaben gemäß § 5 TMG",
        secondHeadlineSecond: "Seitenbetreiber",
        firstFirst: "nOu Kollektiv GbR",
        firstSecond: "vertreten durch Rahel Crawford Barra ",
        firstThird: "Corinthstraße 58",
        firstFourth: "10245 Berlin",
        secondHeadlineThird: "Kontakt",
        contact: "E-Mail: info@nocturnal-unrest.de",
        secondHeadlineFourth: "Haftung für Inhalte",
        second: "Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.",
        secondHeadlineFifth: "Haftung für Links",
        thirdFirst: "Beiträge auf dieser Website können eingebettete Inhalte beinhalten (z. B. Videos, Bilder, Posts etc.). Eingebettete Inhalte von anderen Websites verhalten sich exakt so, als ob der Besucher die andere Website besucht hätte. Wir können für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.",
        thirdSecond: "Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.",
        secondHeadlineSixth: "Urheberrecht",
        fourth: "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des/der jeweiligen Autor:in bzw. Ersteller:in. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen."
    },
    english: {
        headline: "LEGAL NOTICE",
        secondHeadlineFirst: "Information according to § 5 TMG",
        secondHeadlineSecond: "Website operator",
        firstFirst: "nOu-Collective GbR",
        firstSecond: "represented by Rahel Crawford Barra",
        firstThird: "Corinthstraße 58",
        firstFourth: "10245 Berlin",
        firstFifth: "Germany",
        secondHeadlineThird: "Contact",
        contact: "E-Mail: info@nocturnal-unrest.de",
        secondHeadlineSeventh: "Privacy policy",
        secondHeadlineFourth: "Liability for content",
        second: "As a service provider, we are responsible for our own content on these pages in accordance with the general legislation pursuant to Section 7 (1) of the German Telemedia Act (TMG). However, according to §§ 8 to 10 TMG, we as a service provider are not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, liability in this regard is only possible from the point of time at which a concrete infringement of the law becomes known. If we become aware of corresponding infringements, we will remove the respective content immediately.",
        secondHeadlineFifth: "Liability for links",
        thirdFirst: "Contributions on this website may contain embedded content (e.g. videos, images, posts, etc.). Embedded content from other websites behaves exactly as if the visitor had visited the other website. We cannot accept any liability for this third-party content either. The respective provider or operator of the sites is always responsible for the content of the linked sites. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking.",
        thirdSecond: "However, permanent monitoring of the content of the linked pages is not reasonable without concrete indications of a legal violation. If we become aware of any infringements of the law, we will remove such links immediately.",
        secondHeadlineSixth: "Copyright",
        fourth: "The contents and works on these pages created by the site operators are subject to German copyright law. Duplication, processing, distribution or any form of commercialization of such material, beyond the scope of the copyright law, shall require the prior written consent of its respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is marked as such. Should you nevertheless become aware of a copyright infringement, please inform us immediately. If we become aware of any infringements, we will remove such content immediately."
    
    }
}
export {ImpressumContent}